<template>
  <div class="opportunity-details">
    <div class="d-flex header-block flex-column mb-4">
      <div class="col-md-8 pl-0 mb-5">
        <b-button
          class="btn btn-outline-primary btn-sm"
          @click="cancel"
        >
          <i class="ion ion-ios-arrow-back" />
          {{ $t('brokerDealer.opportunitiesPage.buttons.backToList') }}
        </b-button>
      </div>
      <div class="d-flex">
        <h4 class="font-weight-bold mb-0">
          {{ $t('brokerDealer.opportunitiesPage.title') }}
        </h4>
        <div class="d-lg-block text-big font-weight-light mx-1">
          |
        </div>
        <h4 class="font-weight-light">
          {{ originalOpportunityName || '-' }}
        </h4>
      </div>
    </div>
    <div
      v-if="!isLoading && initializedOpportunities && initializedTokens"
      class="opportunity-details-container"
    >
      <div class="opportunity-details-container__header">
        <h5>Broker dealer opportunity details</h5>
        <div class="d-flex">
          <cp-button
            variant="primary"
            class="ml-3"
            size="sm"
            @click="cancel"
          >
            {{ $t('common.button.cancel') }}
          </cp-button>
          <cp-button
            variant="primary"
            class="ml-3"
            icon="ion ion-md-checkmark"
            size="sm"
            @click="updateOrShowModal"
          >
            {{ $t('common.button.saveChanges') }}
          </cp-button>
        </div>
      </div>
      <hr>
      <div class="opportunity-details-container__inputs_container">
        <cp-input
          v-model="model.title"
          class="opportunity-details-container__inputs_container__input mt-3"
          name="Broker Dealer Opportunity name"
          label="Broker Dealer Opportunity name"
        />
        <cp-select
          v-model="model.issuerId"
          class="opportunity-details-container__inputs_container__input"
          input-class="mb-0"
          label="Issuer name"
          name="issuerName"
          :options="issuersOptions"
          :value="model.issuerId"
          @input="onIssuerChange"
        />
        <cp-select
          v-model="model.tokenId"
          class="opportunity-details-container__inputs_container__input"
          input-class="mb-0"
          label="Token"
          name="token"
          :options="tokenOptions"
          :disabled="isLoadingTokens || !model.issuerId"
          :error="!isLoadingTokens && model.issuerId && tokenOptions.length === 1
            ? 'The issuer doesn\'t have any tokens' : ''"
          @input="onTokenChange"
        />
        <cp-select
          v-model="model.opportunityId"
          class="opportunity-details-container__inputs_container__input"
          input-class="mb-0"
          label="Opportunity"
          name="opportunity"
          :disabled="isLoadingOpportunities || !model.tokenId"
          :error="!isLoadingOpportunities && model.tokenId && opportunitiesOptions.length === 1 ?
            'The token doesn\'t have any opportunities' : ''"
          :options="opportunitiesOptions"
        />
      </div>
      <div class="opportunity-details-container__inputs_container">
        <div class="col-md-3">
          <label class="form-label">
            {{ $t('opportunities.editOpportunity.content.imageBox.inputs.image.label') }}
          </label>
          <cp-file-uploader
            id="image-uploader"
            :default-url="model.image"
            extension=".jpeg,.jpg,.png,.gif"
            @onChange="onImageUploaded"
          />
        </div>
        <div class="col-md-9">
          <label class="form-label">
            Description
          </label>
          <b-form-textarea
            v-model="model.description"
            rows="11"
            max-rows="11"
            placeholder="Normally investing at least 80% of assets in blue chip companies (companies whose stock is included...."
            @change="() => { showError = false }"
          />
          <span
            v-if="showError && descriptionInvalid"
            class="invalid-feedback d-block"
          >
            {{ model.description ? ' Description must have 280 characters at max.' : 'Description is required.' }}
          </span>
        </div>
      </div>
      <div class="opportunity-details-container__inputs_container mt-3 mb-5">
        <div class="col-md-4">
          <label class="form-label">
            Tags
          </label>
          <multiselect
            ref="vueSelect"
            :options="availableTags"
            :multiple="true"
            :clear-on-select="true"
            track-by="id"
            label="value"
            :searchable="true"
            :show-labels="false"
            placeholder=""
            @select="onTagSelected"
          >
            <template
              slot="option"
              slot-scope="{ option }"
            >
              <span class="text-capitalize">
                {{ option.value.replaceAll(/_|-/ig, " ") }}
              </span>
            </template>
          </multiselect>
        </div>
        <div class="col-md-12 mt-3">
          <div
            v-for="tag in model.tags"
            :key="tag.id"
            class="selected-filter"
          >
            <span class="mb-0 text-capitalize">{{ tag.value.replaceAll(/_|-/ig, " ") }}</span>
            <i
              class="ion ion-ios-close ml-2"
              @click="() => removeTag(tag)"
            />
          </div>
        </div>
      </div>
      <hr>
      <div class="opportunity-details-container__inputs_container">
        <div class="d-flex w-100">
          <div class="opportunity-details-container__inputs_container__input">
            <cp-switch
              v-model="model.enabled"
              name="isEnabled"
            />
            <span class="mx-3 mb-3">
              {{ $t(`brokerDealer.opportunitiesPage.opportunity${model.enabled ? 'Published' : 'Unpublished'}`) }}
            </span>
          </div>
          <div class="opportunity-details-container__inputs_container__input">
            <cp-switch
              v-model="model.isPublic"
              name="isPublic"
            />
            <span class="mx-3 mb-3">
              {{ $t(`brokerDealer.opportunitiesPage.${model.isPublic ? 'public' : 'private'}`) }}
            </span>
          </div>
        </div>
      </div>
      <cp-general-modal
        ref="cpConfirmModal"
        ok-variant="primary"
        :ok-text="$t('brokerDealer.opportunitiesPage.publishConfirmModal.ok')"
        body-class="m-0"
        @onOk="updateOpportunity"
      >
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h4 class="mb-3">
            {{ $t('brokerDealer.opportunitiesPage.publishConfirmModal.title', [model.title]) }}
          </h4>
          <p class="w-75 text-center">
            {{ $t('brokerDealer.opportunitiesPage.publishConfirmModal.description') }}
          </p>
        </div>
      </cp-general-modal>
    </div>
    <b-spinner
      v-if="isLoading || !initializedOpportunities || !initializedTokens"
      class="opportunity-details__loading"
      big
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import routeNames from '~/utilities/routes';
import CpInput from '@/components/common/standalone-components/inputs/cp-input';
import CpSelect from '@/components/common/standalone-components/inputs/cp-select';
import { CpSwitch } from '@/components/common/standalone-components/inputs';
import CpButton from '@/components/common/standalone-components/cp-button';
import { typeOfAssetOptions } from '@/pages/broker-dealer/opportunities/options';
import CpGeneralModal from '@/components/common/modals-components/general-modal';
import CpFileUploader from '@/components/common/standalone-components/cp-file-uploader';

export default {
  name: 'BrokerDealerOpportunityDetails',
  components: {
    CpFileUploader,
    CpInput,
    CpSelect,
    CpSwitch,
    CpButton,
    CpGeneralModal,
    Multiselect,
  },
  data() {
    return {
      model: {
        issuerId: '',
        tokenId: '',
        opportunityId: '',
        tags: [],
      },
      opportunities: [],
      tokens: [],
      tags: [],
      isLoading: false,
      isLoadingTokens: false,
      isLoadingOpportunities: false,
      opportunityId: this.$route.params.opportunityId,
      originalOpportunityName: '',
      initializedTokens: false,
      initializedOpportunities: false,
      wasPublished: null,
      typeOfAssetOptions,
      showError: false,
    };
  },
  computed: {
    ...mapState('issuerList', ['allSystemIssuersList']),
    ...mapGetters('issuersInfo', ['issuerTokens']),
    issuersOptions() {
      return [{
        text: 'E.g. SPiCE VC',
        value: '',
        disabled: true,
      }]
        .concat((this.allSystemIssuersList.length ? this.allSystemIssuersList : []).map(issuer => ({
          text: issuer.name,
          value: issuer.issuerId,
          disabled: false,
        })));
    },
    tokenOptions() {
      return [{
        text: 'E.g. Token A',
        value: '',
        disabled: true,
      }]
        .concat(this.tokens.map(token => ({
          text: token.name,
          value: token.id,
          disabled: false,
        })));
    },
    opportunitiesOptions() {
      return [{
        text: 'E.g. Fund A',
        value: '',
        disabled: true,
      }]
        .concat(this.opportunities.map(opp => ({
          text: opp.title,
          value: opp.id,
          disabled: false,
        })));
    },
    descriptionInvalid() {
      return !this.model.description || this.model.description.length > 280;
    },
    availableTags() {
      return this.tags.filter(apiTag => !this.model.tags.some(modelTag => modelTag.id === apiTag.id));
    },
  },
  async created() {
    const {
      data: {
        issuerId, tokenId, opportunityId, title, enabled, order, image, description, tags: modelTags, isPublic,
      },
    } = await this.getBrokerDealerOpportunityById(this.opportunityId);
    this.model = {
      issuerId,
      tokenId,
      opportunityId,
      title,
      enabled,
      order,
      image,
      description,
      tags: modelTags || [],
      isPublic,
    };
    this.originalOpportunityName = title;
    this.wasPublished = enabled;

    const [tokens, opps, tags] = await Promise.all([
      this.getIssuerTokens(issuerId),
      this.getIssuerOriginalOpportunities({ issuerId, tokenId }),
      this.getAvailableTags(),
      !this.allSystemIssuersList.length ? this.getAllSystemIssuers() : null,
    ]).finally(() => {
      this.initializedTokens = true;
      this.initializedOpportunities = true;
    });

    this.tokens = tokens;
    this.opportunities = opps;
    this.tags = tags;
  },
  methods: {
    ...mapActions('brokerDealerOpportunities',
      ['updateBrokerDealerOpportunity',
        'getIssuerTokens',
        'getIssuerOriginalOpportunities',
        'getBrokerDealerOpportunityById',
        'getAvailableTags']),
    ...mapActions({
      getAllSystemIssuers: 'issuerList/getAllSystemIssuers',
    }),
    updateOrShowModal() {
      if (!this.wasPublished && this.model.enabled) {
        this.$refs.cpConfirmModal.show();
        return;
      }
      this.updateOpportunity();
    },
    updateOpportunity() {
      if (this.descriptionInvalid) {
        this.showError = true;
        return;
      }
      this.isLoading = true;
      this.$refs.cpConfirmModal.hide();
      const {
        issuerId,
        opportunityId,
        title,
        enabled,
        order,
        description,
        image,
        tags,
        isPublic,
      } = this.model;
      this.updateBrokerDealerOpportunity({
        opportunityId: this.opportunityId,
        opportunity: {
          issuerId,
          opportunityId,
          title,
          enabled,
          order,
          description,
          image,
          tags: tags.map(({ id }) => id),
          isPublic,
        },
      })
        .then(({ enabled: enabledUpdated, title: titleUpdated, image: updatedImage }) => {
          this.wasPublished = enabledUpdated;
          this.originalOpportunityName = titleUpdated;
          this.model.image = updatedImage;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onIssuerChange() {
      this.isLoadingTokens = true;
      this.getIssuerTokens(this.model.issuerId)
        .then((data) => {
          this.tokens = data;
        })
        .finally(() => { this.isLoadingTokens = false; });
    },
    onTokenChange() {
      this.isLoadingOpportunities = true;
      const { issuerId, tokenId } = this.model;
      if (tokenId) {
        this.getIssuerOriginalOpportunities({
          issuerId,
          tokenId,
        })
          .then((data) => { this.opportunities = data; })
          .finally(() => { this.isLoadingOpportunities = false; });
      }
    },
    cancel() {
      this.$router.push(routeNames.brokerDealerOpportunities());
    },
    onImageUploaded({ fileKey }) {
      this.model.image = fileKey || '';
    },
    onTagSelected(newTag) {
      this.model.tags = [newTag, ...this.model.tags];
    },
    removeTag(tagToRemove) {
      this.model.tags = this.model.tags.filter(tag => tag.id !== tagToRemove.id);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
<style scoped lang="scss">
  .opportunity-details {
    width: 100%;
    height: 100%;

    &__loading {
      position: absolute;
      top: calc(50% - 40px);
      left: calc(50% + 80px);
    }
  }
  .opportunity-details-container {
    width: 100%;
    align-items: center;
    padding: 1em 0;
    margin-top: 2em;
    background: white;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1em;

      h5 {
        font-size: 18px;
        color: #55bbc2;
        margin: 0 0 0 1em;
      }
    }

    &__inputs_container {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      padding: 0 1em;

      &__input {
        margin: 1em;
        width: 30%;
      }
    }

    .selected-filter {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5em 1em;
      border-radius: 50px;
      background-color: rgba(97, 125, 141, 0.1);
      margin: 0 1em 1em 0;
      font-size: 14px;
      font-weight: 500;
      color: #697e8d;
      text-transform: capitalize;
      i {
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
</style>
